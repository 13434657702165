<template>
  <div :class="$style.processesList">
    <start-process-panel
      :side-panel-name="sidePanelName"
      :process-id="startProcessId"
    />
    <div :class="$style.description">
      Create a new process to generate documents from a template. Find more
      information in
      <a
        href="https://plumsail.com/docs/documents/v1.x/user-guide/processes/index.html"
        target="_blank"
        rel="noopener noreferrer"
      >the documentation</a>.
    </div>

    <div :class="$style.controls">
      <b-dropdown>
        <template #trigger>
          <b-button
            type="is-primary"
            icon-left="plus"
          >
            Add process
          </b-button>
        </template>

        <b-dropdown-item @click="$router.push({ name: 'DocumentsTemplatesList' })">
          Start from template
        </b-dropdown-item>

        <b-dropdown-item @click="$router.push({ name: 'DocumentsProcessCreate' })">
          Start from blank
        </b-dropdown-item>
      </b-dropdown>

      <b-field v-if="processesLength">
        <b-input
          v-model="search"
          placeholder="Search"
          icon="magnifying-glass"
        />
      </b-field>
    </div>

    <div
      v-if="filteredProcesses.length"
      :class="$style.list"
    >
      <b-table
        :data="filteredProcesses"
        hoverable
        :class="$style.table"
      >
        <b-table-column
          v-slot="{
            row: {
              processName, id, inputType, outputType,
            },
          }"
          field="processName"
          sortable
          label="Name"
          width="50%"
        >
          <div :class="$style.process">
            <div
              :class="$style.info"
              @click="select({ id })"
            >
              <ProcessType
                :input-type="inputType"
                :output-type="outputType"
              />

              <div :class="$style.name">
                {{ processName }}
              </div>
            </div>

            <div :class="[$style.panel, isDropdownOpen === processName && $style.interacts]">
              <button
                type="button"
                class="button is-text"
                :class="$style.start"
                title="Start process"
                @click="start({ id })"
              >
                <font-awesome-icon :icon="['fal', 'play']" />
              </button>

              <button
                type="button"
                class="button is-text"
                :class="$style.edit"
                title="Edit process"
                @click="edit({ id })"
              >
                <font-awesome-icon :icon="['fal', 'pencil']" />
              </button>

              <div :class="$style.menu">
                <b-dropdown
                  :ref="processName"
                  position="is-bottom-left"
                  :animation="''"
                  @active-change="changeIsDropdownOpen($event, processName)"
                >
                  <template #trigger>
                    <div
                      :class="$style.trigger"
                      title="Menu"
                    >
                      <font-awesome-icon :icon="['fal', 'ellipsis-vertical']" />
                    </div>
                  </template>

                  <b-dropdown-item
                    :class="$style.copy"
                    @click="copy({ id, processName })"
                  >
                    <font-awesome-icon :icon="['fal', 'clone']" />

                    <div :class="$style.text">
                      Clone
                    </div>
                  </b-dropdown-item>

                  <b-dropdown-item
                    :class="$style.remove"
                    @click="remove({ id, processName })"
                  >
                    <font-awesome-icon :icon="['fal', 'trash-can']" />

                    <div :class="$style.text">
                      Delete
                    </div>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
        </b-table-column>

        <b-table-column
          v-slot="{ row: { processMode } }"
          field="processMode"
          sortable
          label="Mode"
        >
          {{ processMode }}
        </b-table-column>

        <b-table-column
          v-slot="{ row: { processLastRun } }"
          sortable
          :custom-sort="sortByDate"
          field="processLastRun.start"
          label="Last run"
        >
          {{ processLastRun.start
            ? formatDate({ date: processLastRun.start, format: 'MMM DD, YYYY, hh:mm A' })
            : 'Never'
          }}
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapState, mapActions } from 'pinia';
import { useProcessesStore, useHelpStore } from '@/stores/index.js';
import ProcessType from '@/views/documents/common/components/process-type/index.vue';
import processMixin from './mixins/process-mixin.js';
import { formatDate } from '@/utils/index.js';

export default {
  name: 'ProcessesList',
  components: {
    ProcessType,
  },
  mixins: [processMixin],
  data() {
    return {
      search: '',
      isDropdownOpen: false,
      startProcessId: null,
    };
  },
  computed: {
    ...mapState(useProcessesStore, ['processes']),

    processesLength() {
      return this.processes?.length;
    },

    filteredProcesses() {
      return this.search
        ? this.processes.filter(({ processName }) => processName
          .toLowerCase()
          .includes(this.search.toLowerCase()))
        : this.processes || [];
    },
  },

  async created() {
    await this.readProcesses();

    this.setHelpOpen(true);
  },

  beforeDestroy() {
    this.setHelpOpen(false);
  },

  methods: {
    formatDate,
    ...mapActions(useHelpStore, { setHelpOpen: 'setOpen' }),
    ...mapActions(useProcessesStore, ['readProcesses']),

    sortByDate(a, b, isAsc) {
      if (a.processLastRun.runStatus === 'Unknown') {
        return 0;
      }
      if (b.processLastRun.runStatus === 'Unknown') {
        return -1;
      }
      const now = Date.now();
      const dateA = dayjs(a.processLastRun.start || now).valueOf();
      const dateB = dayjs(b.processLastRun.start || now).valueOf();

      return isAsc ? dateA - dateB : dateB - dateA;
    },

    changeIsDropdownOpen(isOpen, name) {
      if (!isOpen && name !== this.isDropdownOpen) return;
      if (isOpen) this.isDropdownOpen = name;
      else this.isDropdownOpen = isOpen;
    },
  },
};
</script>

<style lang="scss" module>
.processesList {
  .description {
    margin-bottom: 25px;
  }

  .controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .table {
    td[data-label='Name'] {
      position: relative;
      padding: 0;
    }

    tr:hover {
      .panel {
        visibility: visible;
      }
    }
  }

  .process {
    position: absolute;
    display: flex;
    justify-content: space-between;
    inset: 0;

    .info {
      display: flex;
      align-items: center;
      gap: 15px;
      padding-left: 15px;
      width: calc(100% - 130px);
      cursor: pointer;

      .name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .panel {
      display: flex;
      padding-right: 15px;
      visibility: hidden;

      &.interacts {
        visibility: visible;
      }

      .start,
      .edit,
      .menu,
      .trigger {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        cursor: pointer;
      }

      .start,
      .edit {
        transition-property: transform;
        transition-duration: var(--transition-duration);

        &:hover,
        &:focus  {
          outline: none;
          box-shadow: none;
          border-color: var(--color-gray);
          background-color: var(--color-gray);
        }
      }

      .copy,
      .remove {
        .text {
          display: inline;
          margin-left: 5px;
        }
      }

      .trigger {
        display: flex;
        align-items: center;
        height: 100%;

        svg {
          width: 15px;
          height: 25px;
        }
      }

      :global(.dropdown) {
        height: 100%;

        &:hover {
          background-color: var(--color-gray);
        }
      }

      :global(.dropdown.is-active) {
        background-color: var(--color-gray);
      }

      :global(.dropdown-trigger) {
        height: 100%;
      }

      :global(.dropdown-menu) {
        padding-top: 1px;
        min-width: initial;
      }

      :global(.dropdown-item) {
        padding-right: 30px;
        padding-left: 17px;
      }
    }
  }
}

@media (max-width: 768px) {
  .processesList {

    .controls {
      flex-direction: column;
      align-items: stretch;

      &>*:not(:last-child) {
        margin-bottom: 30px;
      }
    }

    .table {
      td[data-label='Name'] {
        padding: 0.5em 0.75em;
        position: static;
      }
    }

    .process {
      position: absolute;
      top: 0;
      align-items: flex-start;
      padding: 8px 12px;
      padding-left: 66px;

      .panel {
        display: none;
      }

      .info {
        width: calc(100%);
        justify-content: flex-end;

        &::before {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          content: '';
        }

        .name {
          order: -1;
        }
      }
    }

    :global(.table-mobile-sort) {
      display: none;
    }
  }
}
</style>
